export class User {
    
    id: number;
    first_name: string;
    last_name: string;
    
    [propName: string]: any;
    
    /**
     * Constructs class and appends additional properties.
     *
     * @param {User} user
     */
    constructor(user: User) {
        user.full_name = this.getFullName(user);
        Object.assign(this, user);
    }
    
    /**
     * Concatenates first and last names to form full name.
     *
     * @param {User} user
     *
     * @returns {string}
     */
    getFullName(user: User): string {
        return [user.first_name, user.last_name].filter(name => !!name).join(' ') || null;
    }
    
}
