import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UIApp, UIAppModule, UIDataItem, UIHelperService, UIMainNavItem, UIModalService, UIRestService, UISessionService, UIThemeService } from '@clickdealer/ngx-apps';

import { environment } from '@environments/environment';

import { Dealer } from '@global/models/dealer.model';
import { User } from '@global/models/user.model';

@Injectable()
export class AppResolver implements Resolve<any> {
    
    /**
     * Constructs class and injects dependencies.
     *
     * @param {UISessionService} uiSessionService
     * @param {UIHelperService} uiHelperService
     * @param {UIModalService} uiModalService
     * @param {UIThemeService} uiThemeService
     * @param {UIRestService} uiRestService
     * @param {Router} router
     * @param {DomSanitizer} domSanitizer
     */
    constructor(
        private uiSessionService: UISessionService,
        private uiHelperService: UIHelperService,
        private uiModalService: UIModalService,
        private uiThemeService: UIThemeService,
        private uiRestService: UIRestService,
        private router: Router,
        private domSanitizer: DomSanitizer
    ) {
    }
    
    /**
     *
     *
     * @param {ActivatedRouteSnapshot} activatedRouteSnapshot
     *
     * @returns {Observable<UIApp>}
     */
    resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<any> {
        let uiApp: UIApp = this.uiSessionService.getItem('uiApp');
        
        if (!uiApp) {
            
            uiApp = new UIApp({
                name: 'Click Consumer App',
                acronym: 'CCA',
                showModuleSwitcher: false,
                showSearch: false,
                navPosition: 'top',
                audience: 'consumer',
                environment: environment,
                modules: [
                    new UIAppModule({
                        name: 'Customer Profile',
                        acronym: 'CP',
                        theme: 'teal',
                        path: 'customer-profile'
                    })
                ],
                mainNavItems: [
                    new UIMainNavItem({
                        name: 'Contact',
                        icon: 'fas fa-envelope',
                        click: (): void => {
                            this.uiModalService.show(['global/dealer-contact']);
                        }
                    }),
                    new UIMainNavItem({
                        name: 'Visit Us',
                        icon: 'fas fa-map-marker-alt',
                        click: (): void => {
                            this.uiModalService.show(['global/dealer-map']);
                        }
                    })
                ]
            });
            
            this.uiSessionService.setItem('uiApp', uiApp);
        }
        
        return this.resolveUser();
        
    }
    
    /**
     * Gets and sets current user and dealer.
     *
     * @returns {Observable<any>}
     */
    resolveUser(): Observable<any> {
        
        const cachedMe: UIDataItem = this.uiSessionService.getItem('me');
        
        if (cachedMe) {
            return of(cachedMe);
        }
        
        return this.uiRestService.one(['me'], {include: ['contact-preferences', 'terms', 'dealer.branding', 'dealer.primary-addresses']}).pipe(map((user: User) => {
            
            const currentUser: User = new User(user);
            const currentDealer: Dealer = new Dealer(user.dealer);
            const address: any = currentDealer.primary_addresses.data[0];
            
            if (currentDealer.sub_domain !== environment.subDomain) {
                this.router.navigate(['/error', '403']);
                return;
            }
            
            if (address) {
                currentDealer.address = this.uiHelperService.buildAddress([address.line1, address.line2, address.line3, address.city, address.county, address.postal_code]);
                currentDealer.map_link = this.uiHelperService.getMapLink(currentDealer.name + ',' + currentDealer.primary_addresses.data[0].postal_code, false);
                currentDealer.map_embed_link = this.uiHelperService.getMapLink(currentDealer.name + ',' + currentDealer.primary_addresses.data[0].postal_code, true);
            }
            
            this.uiSessionService.setItem('me', currentUser);
            this.uiSessionService.setItem('dealer', currentDealer);
            
            return currentUser;
            
        }));
    }
    
}
