import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UIStorageService } from '@clickdealer/ngx-apps';

@Component({
    selector: 'start-component',
    template: '',
})
export class StartComponent implements OnInit, OnDestroy {
    destroyed$: Subject<boolean> = new Subject<boolean>();

    /**
     * Constructs class and injects dependencies.
     */
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private uiStorageService: UIStorageService
    ) {}

    /**
     * Lifecycle hook: Subscribes to router params and handles sso and redirect.
     */
    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
            if (queryParams['token']) {
                this.uiStorageService.setItem('token', queryParams['token']);
            }

            if (queryParams['initiator']) {
                this.uiStorageService.setItem('initiator', queryParams['initiator']);
            }

            if (queryParams['redirect']) {
                this.router.navigate([queryParams['redirect']]);
            }
        });
    }

    /**
     * Lifecycle hook: Destroys any open subscriptions.
     */
    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
}
