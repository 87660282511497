import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UIActionAnchorButton, UIDataItem, UIModalService, UIResponsiveService, UISessionService } from '@clickdealer/ngx-apps';

@Component({
    selector: 'gbl-dealer-map-modal',
    templateUrl: './dealer-map.component.html',
    styleUrls: ['./dealer-map.component.less']
})

export class GBLDealerMapModalComponent implements OnDestroy {
    
    dealer: UIDataItem = this.uiSessionService.getItem('dealer');
    destroyed$: Subject<boolean> = new Subject<boolean>();
    
    /**
     * Constructs class and injects dependencies.
     * Constructs modal config.
     * Subscribes to dealer from session service.
     *
     * @param {UIModalService} uiModalService
     * @param {UISessionService} uiSessionService
     * @param {UIResponsiveService} uiResponsiveService
     */
    constructor(
        private uiModalService: UIModalService,
        private uiSessionService: UISessionService,
        private uiResponsiveService: UIResponsiveService
    ) {
        
        this.uiModalService.setConfig({
            title: 'Visit Us',
            position: this.uiResponsiveService.screenIsLTE('sm3') ? 'left' : 'right',
            width: 'md2',
            actions: [
                new UIActionAnchorButton({
                    type: 'anchorButton',
                    text: 'Open with Google Maps',
                    theme: 'primary',
                    href: this.dealer.map_link,
                    target: '_blank'
                })
            ]
        });
        
        this.uiSessionService.getSubject('dealer').pipe(takeUntil(this.destroyed$)).subscribe((dealer: UIDataItem) => {
            this.dealer = dealer;
        });
        
    }
    
    /**
     * Destroys component and any active subscriptions.
     */
    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
    
}
