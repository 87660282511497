import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material';

import { UICoreModule, UIToastModule } from '@clickdealer/ngx-apps';

import { GBLModule } from '@app/global/global.module';

import { AppRouterModule } from '@app/app.router.module';

import { AppHttpErrorComponent } from './global/http-error/http-error.component';
import { StartComponent } from './global/components/start.component';
import { BootstrapComponent } from '@app/bootstrap.component';
import { AppComponent } from '@app/app.component';

import { AppResolver } from '@app/app.resolver';

import { AppErrorHandler } from '@app/app.error-handler';

@NgModule({
    declarations: [BootstrapComponent, AppHttpErrorComponent, StartComponent, AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatSliderModule,

        UICoreModule.forRoot(),
        UIToastModule,

        GBLModule,
        AppRouterModule,
    ],
    providers: [
        AppResolver,
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler,
        },
    ],
    bootstrap: [BootstrapComponent],
})
export class AppModule {}
