import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
    UIActionLinkButton,
    UIApp,
    UIAppModule,
    UIFooter,
    UIHelperService,
    UILayoutConfig,
    UIModalService,
    UIResponsiveService,
    UIScrollService,
    UISessionService,
    UIStorageService,
    UIThemeService,
} from '@clickdealer/ngx-apps';

import { DataLayerService } from './global/services/data-layer.service';
import { TrackingService } from './global/services/tracking.service';

import { environment } from '@environments/environment';

@Component({
    selector: 'cca-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
    uiApp: UIApp = this.uiSessionService.getItem('uiApp');
    uiAppModule: UIAppModule = this.uiSessionService.getItem('uiAppModule');
    uiFooter: UIFooter = this.getFooter();
    uiLayoutConfig: UILayoutConfig = this.uiSessionService.getItem('uiLayoutConfig');
    dealer: any = this.uiSessionService.getItem('dealer');
    appLoaded: boolean = false;
    productionMode: boolean = environment.production;
    destroyed$: Subject<boolean> = new Subject<boolean>();

    /**
     * Constructs class and injects dependencies.
     */
    constructor(
        private uiSessionService: UISessionService,
        private uiScrollService: UIScrollService,
        private uiModalService: UIModalService,
        private uiHelperService: UIHelperService,
        private uiThemeService: UIThemeService,
        public uiResponsiveService: UIResponsiveService,
        private dataLayerService: DataLayerService,
        private uiStorageService: UIStorageService,
        private trackingService: TrackingService
    ) {
        const dealerName: string = this.dealer.brand_name ? this.dealer.brand_name : this.dealer.name;
        this.uiApp.name = dealerName;
        this.uiApp.acronym = this.uiHelperService.acronymise(dealerName, 3);
        this.uiSessionService.setItem('uiApp', this.uiApp);

        this.uiThemeService.setFromBranding(this.dealer.branding).subscribe(() => {
            this.appLoaded = true;
        });

        this.uiSessionService
            .getSubject('uiApp')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((uiApp: UIApp) => {
                this.uiApp = uiApp;
            });

        this.uiSessionService
            .getSubject('uiApp')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((uiApp: UIApp) => {
                this.uiApp = uiApp;
            });

        this.uiSessionService
            .getSubject('uiAppModule')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((uiAppModule: UIAppModule) => {
                this.uiAppModule = uiAppModule;
            });

        this.uiSessionService
            .getSubject('uiLayoutConfig')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((uiLayoutConfig: UILayoutConfig) => {
                this.uiLayoutConfig = uiLayoutConfig;
            });

        this.uiSessionService
            .getSubject('uiFooter')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((uiFooter: UIFooter) => {
                this.uiFooter = uiFooter;
            });
    }

    /**
     * Initialises GTM
     */
    ngOnInit(): void {
        this.initGoogleTagManager();
    }

    /**
     * Gets footer.
     *
     * @returns {UIFooter}
     */
    getFooter(): UIFooter {
        return new UIFooter({
            links: [
                new UIActionLinkButton({
                    type: 'linkButton',
                    text: 'Terms of Use',
                    click: (): void => {
                        this.uiModalService.show(['legal-info', 'terms-of-use']);
                    },
                }),
                new UIActionLinkButton({
                    type: 'linkButton',
                    text: 'Privacy Policy',
                    click: (): void => {
                        this.uiModalService.show(['legal-info', 'privacy-policy']);
                    },
                }),
                new UIActionLinkButton({
                    type: 'linkButton',
                    text: 'Cookie Policy',
                    click: (): void => {
                        this.uiModalService.show(['legal-info', 'cookie-policy']);
                    },
                }),
            ],
        });
    }

    /**
     * Initializes tag manager scrript
     */
    initGoogleTagManager(): void {
        (<any>window).initGoogleTagManager(
            window,
            document,
            'script',
            'dataLayer',
            environment.gtmContainerId,
            environment.gtmEnvironmentId,
            environment.gtmEnvironmentAuth
        );

        setTimeout(() => {
            this.dataLayerService.push({ GA_ID: 'UA-118038460-1' });

            const initiator = this.uiStorageService.getItem('initiator');

            if (initiator) {
                this.trackingService.trackCustomEvent(`visit_initiator[${initiator}]`);
            } else {
                this.trackingService.trackCustomEvent('no_initiator_passed');
            }
        }, 1000);
    }

    /**
     * Lifecycle hook: Destroys component and any open subscriptions.
     */
    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
}
