<!--  OVERLAY  -->

<div class="error-overlay"></div>

<!--  END OVERLAY  -->

<!--  ERROR FRAME  -->

<div class="error-frame">

    <!--  ERROR HEADER  -->

    <div class="error-frame-header">
        <h2>{{currentError.title}}</h2>
    </div>

    <!-- END ERROR HEADER  -->

    <!--  ERROR BODY  -->

    <div #errorBody class="error-frame-body written-content" [class.is-scrolling]="isBodyScrolling()" [class.has-footer]="currentError.showReload || (currentError.log === 'ask' && uiApp)">

        <!--  ERROR MESSAGES LOOP  -->

        <p *ngFor="let message of currentError.message; let index = index">
            <strong *ngIf="index === 0">{{message}}</strong>
            <span *ngIf="index !== 0">{{message}}</span>
        </p>

        <!--  END ERROR MESSAGES LOOP  -->

        <!--  REPORT MESSAGE  -->

        <p *ngIf="currentError.log === 'ask' && uiApp">If you believe this to be incorrect please report the issue by clicking below and we'll look into it.</p>

        <!--  END REPORT MESSAGE  -->

    </div>

    <!--  END ERROR BODY  -->

    <!--  ERROR FOOTER  -->

    <div *ngIf="currentError.showReload || (currentError.log === 'ask' && uiApp)" class="error-frame-footer">

        <!--  RELOAD BUTTON  -->

        <a *ngIf="currentError.showReload" class="button primary-button" (click)="onReload()">Reload Page</a>

        <!--  END RELOAD BUTTON  -->

        <!--  REPORT BUTTON  -->

        <a *ngIf="currentError.log === 'ask' && uiApp && !logged" class="button primary-button" (click)="onLog()">Send Report</a>

        <!--  END REPORT BUTTON  -->

        <!--  REPORTED MESSAGE  -->

        <div *ngIf="currentError.log === 'ask' && uiApp && logged" class="icon-status"><i class="far fa-check pos-bg pos-fg-col"></i> Thanks for sending your report!</div>

        <!--  END REPORTED MESSAGE  -->

    </div>

    <!--  END ERROR FOOTER  -->

</div>

<!--  END ERROR FRAME  -->
