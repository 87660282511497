import { UIStorageService } from '@clickdealer/ngx-apps';
import { DataLayerService } from './data-layer.service';
import { StructuredEvent } from '../models/structured-event.model';
import * as i0 from "@angular/core";
import * as i1 from "@clickdealer/ngx-apps";
import * as i2 from "./data-layer.service";
var TrackingService = /** @class */ (function () {
    /**
     * Construct the Tracking Service.
     */
    function TrackingService(storageService, dataLayerService) {
        this.storageService = storageService;
        this.dataLayerService = dataLayerService;
        this.dnt = this.storageService.getItem('dnt') === 'true';
    }
    /**
     * Tracks a custom event.
     */
    TrackingService.prototype.trackCustomEvent = function (value) {
        this.dispatchToDataLayer('customEvent', new StructuredEvent('click_gdpr_' + value, 'journey', 'custom_event', 'click_gdpr_' + value));
    };
    /**
     * Dispatch Job to DataLayer.
     */
    TrackingService.prototype.dispatchToDataLayer = function (event_name, structured_event) {
        if (!this.dnt) {
            this.dataLayerService.push({
                event: event_name,
                structuredEvent: structured_event,
            });
        }
    };
    TrackingService.ngInjectableDef = i0.defineInjectable({ factory: function TrackingService_Factory() { return new TrackingService(i0.inject(i1.UIStorageService), i0.inject(i2.DataLayerService)); }, token: TrackingService, providedIn: "root" });
    return TrackingService;
}());
export { TrackingService };
