import { Injectable } from '@angular/core';

import { UIStorageService } from '@clickdealer/ngx-apps';

import { DataLayerService } from './data-layer.service';

import { StructuredEvent } from '../models/structured-event.model';

@Injectable({ providedIn: 'root' })
export class TrackingService {
    dnt: boolean = this.storageService.getItem('dnt') === 'true';

    /**
     * Construct the Tracking Service.
     */
    constructor(private storageService: UIStorageService, private dataLayerService: DataLayerService) {}

    /**
     * Tracks a custom event.
     */
    trackCustomEvent(value: string): void {
        this.dispatchToDataLayer(
            'customEvent',
            new StructuredEvent('click_gdpr_' + value, 'journey', 'custom_event', 'click_gdpr_' + value)
        );
    }
    /**
     * Dispatch Job to DataLayer.
     */
    protected dispatchToDataLayer(event_name: string, structured_event: StructuredEvent): void {
        if (!this.dnt) {
            this.dataLayerService.push({
                event: event_name,
                structuredEvent: structured_event,
            });
        }
    }
}
