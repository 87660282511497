export const environment: any = {
    name: 'production',
    production: true,
    apiUrl: 'https://consumer.clickapi.co.uk',
    assetsUrl: 'https://prod-click-dealer-consumer-assets.s3.eu-west-2.amazonaws.com',
    subDomain: window.location.host.split('.')[0],
    domain: 'clickconsumer.co.uk',

    gtmContainerId: 'GTM-WC3R83P',
    gtmEnvironmentId: null,
    gtmEnvironmentAuth: null,
};
