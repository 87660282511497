/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@clickdealer/ngx-apps/ngx-apps.ngfactory";
import * as i2 from "@clickdealer/ngx-apps";
import * as i3 from "./dealer-contact.component";
var styles_GBLDealerContactModalComponent = [];
var RenderType_GBLDealerContactModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GBLDealerContactModalComponent, data: {} });
export { RenderType_GBLDealerContactModalComponent as RenderType_GBLDealerContactModalComponent };
export function View_GBLDealerContactModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ui-key-value-list", [], null, null, null, i1.View_UIKeyValueListComponent_0, i1.RenderType_UIKeyValueListComponent)), i0.ɵdid(1, 638976, null, 0, i2.UIKeyValueListComponent, [i2.UIResponsiveService], { items: [0, "items"], style: [1, "style"], size: [2, "size"], adjacentAt: [3, "adjacentAt"], keyCols: [4, "keyCols"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dealerContactItems; var currVal_1 = "striped"; var currVal_2 = "large"; var currVal_3 = "sm3"; var currVal_4 = 15; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_GBLDealerContactModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gbl-dealer-contact-modal", [], null, null, null, View_GBLDealerContactModalComponent_0, RenderType_GBLDealerContactModalComponent)), i0.ɵdid(1, 180224, null, 0, i3.GBLDealerContactModalComponent, [i2.UIModalService, i2.UISessionService, i2.UIResponsiveService], null, null)], null, null); }
var GBLDealerContactModalComponentNgFactory = i0.ɵccf("gbl-dealer-contact-modal", i3.GBLDealerContactModalComponent, View_GBLDealerContactModalComponent_Host_0, {}, {}, []);
export { GBLDealerContactModalComponentNgFactory as GBLDealerContactModalComponentNgFactory };
