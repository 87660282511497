/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@clickdealer/ngx-apps/ngx-apps.ngfactory";
import * as i3 from "@clickdealer/ngx-apps";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./app.component";
import * as i7 from "./global/services/data-layer.service";
import * as i8 from "./global/services/tracking.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ui-footer", [], null, null, null, i2.View_UIFooterComponent_0, i2.RenderType_UIFooterComponent)), i1.ɵdid(1, 49152, null, 0, i3.UIFooterComponent, [], { links: [0, "links"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uiFooter == null) ? null : _co.uiFooter.links); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "responsive-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uiResponsiveService.getScreenSize(); _ck(_v, 1, 0, currVal_0); }); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "app"], ["id", "app"]], [[2, "top-nav", null], [2, "consumer", null], [2, "has-module-nav", null], [2, "has-view-header", null], [2, "has-key-functions", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ui-nav", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).setTooltipConfig() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UINavComponent_0, i2.RenderType_UINavComponent)), i1.ɵdid(2, 180224, null, 0, i3.UINavComponent, [i4.Router, i3.UIModalService, i3.UISessionService, i3.UIResponsiveService, i3.UILayoutConfigService, i3.UIThemeService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ui-toast", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UIToastComponent_0, i2.RenderType_UIToastComponent)), i1.ɵdid(9, 245760, null, 0, i3.UIToastComponent, [i3.UIToastService, i3.UISessionService, i3.UIResponsiveService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_5 = _co.uiFooter; _ck(_v, 7, 0, currVal_5); _ck(_v, 9, 0); var currVal_6 = !_co.productionMode; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.uiApp.navPosition === "top"); var currVal_1 = (_co.uiApp.audience === "consumer"); var currVal_2 = _co.uiAppModule.hasModuleNav; var currVal_3 = (((_co.uiLayoutConfig == null) ? null : _co.uiLayoutConfig.hasViewHeader) !== false); var currVal_4 = ((((_co.uiLayoutConfig == null) ? null : _co.uiLayoutConfig.hasViewHeader) !== false) && (((_co.uiLayoutConfig == null) ? null : _co.uiLayoutConfig.hasKeyFunctions) !== false)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "loading loading-large pri-aft-br"]], null, null, null, null, null))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.appLoaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cca-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i6.AppComponent, [i3.UISessionService, i3.UIScrollService, i3.UIModalService, i3.UIHelperService, i3.UIThemeService, i3.UIResponsiveService, i7.DataLayerService, i3.UIStorageService, i8.TrackingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("cca-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
