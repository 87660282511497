/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./start.component";
import * as i2 from "@angular/router";
import * as i3 from "@clickdealer/ngx-apps";
var styles_StartComponent = [];
var RenderType_StartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StartComponent, data: {} });
export { RenderType_StartComponent as RenderType_StartComponent };
export function View_StartComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_StartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "start-component", [], null, null, null, View_StartComponent_0, RenderType_StartComponent)), i0.ɵdid(1, 245760, null, 0, i1.StartComponent, [i2.Router, i2.ActivatedRoute, i3.UIStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartComponentNgFactory = i0.ɵccf("start-component", i1.StartComponent, View_StartComponent_Host_0, {}, {}, []);
export { StartComponentNgFactory as StartComponentNgFactory };
