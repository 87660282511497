import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    /**
     * Constructs class and injects dependencies.
     *
     * @param {Injector} injector
     */
    constructor(private injector: Injector) {}

    /**
     * Handles error by redirecting to the error route.
     * @param error
     */
    handleError(error: any): void {
        const router: Router = this.injector.get(Router);
        router.navigate(['/error'], {skipLocationChange: true});
        throw error;
    }

}
