/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.min.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./http-error.component";
import * as i4 from "@angular/router";
import * as i5 from "@clickdealer/ngx-apps";
var styles_AppHttpErrorComponent = [i0.styles];
var RenderType_AppHttpErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppHttpErrorComponent, data: {} });
export { RenderType_AppHttpErrorComponent as RenderType_AppHttpErrorComponent };
function View_AppHttpErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_AppHttpErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_AppHttpErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.index !== 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_AppHttpErrorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you believe this to be incorrect please report the issue by clicking below and we'll look into it."]))], null, null); }
function View_AppHttpErrorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "button primary-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onReload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reload Page"]))], null, null); }
function View_AppHttpErrorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "button primary-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send Report"]))], null, null); }
function View_AppHttpErrorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "icon-status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-check pos-bg pos-fg-col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thanks for sending your report!"]))], null, null); }
function View_AppHttpErrorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "error-frame-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentError.showReload; _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.currentError.log === "ask") && _co.uiApp) && !_co.logged); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.currentError.log === "ask") && _co.uiApp) && _co.logged); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AppHttpErrorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { errorBody: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "error-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "error-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "error-frame-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["errorBody", 1]], null, 4, "div", [["class", "error-frame-body written-content"]], [[2, "is-scrolling", null], [2, "has-footer", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHttpErrorComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.currentError.message; _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.currentError.log === "ask") && _co.uiApp); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.currentError.showReload || ((_co.currentError.log === "ask") && _co.uiApp)); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentError.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.isBodyScrolling(); var currVal_2 = (_co.currentError.showReload || ((_co.currentError.log === "ask") && _co.uiApp)); _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_AppHttpErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-http-error", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isBodyScrolling() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppHttpErrorComponent_0, RenderType_AppHttpErrorComponent)), i1.ɵdid(1, 180224, null, 0, i3.AppHttpErrorComponent, [i4.ActivatedRoute, i5.UISessionService, i5.UIToastService, i5.UIStorageService], null, null)], null, null); }
var AppHttpErrorComponentNgFactory = i1.ɵccf("app-http-error", i3.AppHttpErrorComponent, View_AppHttpErrorComponent_Host_0, {}, {}, []);
export { AppHttpErrorComponentNgFactory as AppHttpErrorComponentNgFactory };
