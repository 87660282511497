<!--  APP  -->

<div *ngIf="appLoaded"
     id="app"
     class="app"
     [class.top-nav]="uiApp.navPosition === 'top'"
     [class.consumer]="uiApp.audience === 'consumer'"
     [class.has-module-nav]="uiAppModule.hasModuleNav"
     [class.has-view-header]="uiLayoutConfig?.hasViewHeader !== false"
     [class.has-key-functions]="uiLayoutConfig?.hasViewHeader !== false && uiLayoutConfig?.hasKeyFunctions !== false">

    <!--  NAV  -->

    <ui-nav></ui-nav>

    <!--  END NAV  -->

    <!--  APP CONTENT  -->

    <div class="app-content">

        <!--  PRIMARY OUTLET  -->

        <router-outlet></router-outlet>

        <!--  END PRIMARY OUTLET  -->

    </div>

    <!--  END APP CONTENT  -->

    <!--  FOOTER  -->

    <ui-footer *ngIf="uiFooter" [links]="uiFooter?.links"></ui-footer>

    <!--  END FOOTER  -->

    <!--  TOASTS  -->

    <ui-toast></ui-toast>

    <!--- END TOASTS  -->

    <!--  SCREEN SIZE INDICATOR  -->

    <div *ngIf="!productionMode" class="responsive-indicator">{{ uiResponsiveService.getScreenSize() }}</div>

    <!--  END SCREEN SIZE INDICATOR  -->

</div>

<!--  END APP  -->

<!--  LOADING  -->

<div *ngIf="!appLoaded" class="loading loading-large pri-aft-br"></div>

<!--  END LOADING  -->
