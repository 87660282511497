import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIKeyValueListModule } from '@clickdealer/ngx-apps';

import { GBLRouterModule } from './global.router.module';

import { GBLDealerContactModalComponent } from './modal-components/dealer-contact/dealer-contact.component';
import { GBLDealerMapModalComponent } from './modal-components/dealer-map/dealer-map.component';

@NgModule({
    declarations: [
        GBLDealerContactModalComponent,
        GBLDealerMapModalComponent
    ],
    imports: [
        CommonModule,
        
        UIKeyValueListModule,
        
        GBLRouterModule
    ],
    providers: [],
    exports: []
})
export class GBLModule {
}
