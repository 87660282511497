export class StructuredEvent {
    name: string;
    category: string;
    action: string;
    label?: string | null;
    value?: number | null;

    constructor(name: string, category: string, action: string, label?: string, value?: number) {
        this.name = name;
        this.category = category;
        this.action = action;
        this.label = label || null;
        this.value = value || null;
    }
}
