import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataLayerService {
    dataLayer = (<any>window)['dataLayer'];

    /**
     * Constructs class and injects dependencies.
     */
    constructor() {
        this.dataLayer = this.dataLayer || [];
    }

    /**
     * Pushes item to data layer.
     */
    push(item: any) {
        this.dataLayer.push(item);
    }
}
