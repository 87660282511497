import { Component, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import {
    UIApp,
    UIError,
    UISessionService,
    UIToastService,
    UIStorageService,
} from "@clickdealer/ngx-apps";

@Component({
    selector: "app-http-error",
    templateUrl: "./http-error.component.html",
    styleUrls: ["./error.min.css"],
})
export class AppHttpErrorComponent implements OnDestroy {
    @ViewChild("errorBody") errorBody;

    uiApp: UIApp = this.uiSessionService.getItem("uiApp");
    currentError: UIError;
    logged: boolean = false;
    destroyed$: Subject<boolean> = new Subject<boolean>();

    /**
     * Constructs class and injects dependencies.
     * Subscribes to route param changes.
     *
     * @param {ActivatedRoute} activatedRoute
     * @param {UISessionService} uiSessionService
     * @param {UIToastService} uiToastService
     * @param {UICookieStorageService} uiStorageService
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private uiSessionService: UISessionService,
        private uiToastService: UIToastService,
        private uiStorageService: UIStorageService
    ) {
        this.activatedRoute.params
            .pipe(takeUntil(this.destroyed$))
            .subscribe((params: any) => {
                const statusCode: number = parseInt(params["status"]);
                this.currentError = this.getError(statusCode);

                if (this.currentError.log === "always") {
                    this.onLog();
                }
            });

        this.uiSessionService
            .getSubject("uiApp")
            .pipe(takeUntil(this.destroyed$))
            .subscribe((uiApp: UIApp) => {
                this.uiApp = uiApp;
            });
    }

    /**
     * Gets current error based on status from route param.
     *
     * @param {string} status
     * @returns {UIError}
     */
    getError(status: number): UIError {
        switch (status) {
            case 401:
                return {
                    status: status,
                    title: "Unauthorised",
                    message: ["You're not authorised to view this resource."],
                    log: "ask",
                    showReload: false,
                };

            case 403:
                return {
                    status: status,
                    title: "Forbidden",
                    message: ["Access to that resource is not allowed."],
                    log: "ask",
                    showReload: false,
                };

            case 404:
                return {
                    status: status,
                    title: "Not Found",
                    message: ["The requested resource was not found."],
                    log: "ask",
                    showReload: false,
                };

            case 429:
            case 502:
                return {
                    status: status,
                    title: "High Traffic",
                    message: [
                        "This app is currently experiencing a high amount of traffic, please try again later.",
                        "We apologise for any inconvenience and we hope to be back up and running as soon as possible.",
                    ],
                    log: "always",
                    showReload: true,
                };

            case 503:
                return {
                    status: status,
                    title: "Under Maintenance",
                    message: [
                        "This app is currently under maintenance.",
                        "We apologise for any inconvenience and we hope to be back up and running as soon as possible.",
                    ],
                    log: "always",
                    showReload: true,
                };

            default:
                return {
                    status: status,
                    title: "Something went wrong",
                    message: [
                        "We're sorry, there was a problem with our service.",
                        "We have automatically sent an error report so that we can investigate. Apologies for any inconvenience.",
                    ],
                    log: "always",
                    showReload: true,
                };
        }
    }

    /**
     * Checks if body is scrolling.
     *
     * @returns {boolean}
     */
    @HostListener("window:resize")
    isBodyScrolling(): boolean {
        return (
            this.errorBody.nativeElement.scrollHeight >
            this.errorBody.nativeElement.clientHeight
        );
    }

    /**
     * Logs error to Elastic APM.
     */
    onLog(): void {
        // todo: log to elastic apm
    }

    /**
     * Reloads current location.
     */
    onReload(): void {
        location.reload();
    }

    /**
     * Destroys component and any active subscriptions.
     */
    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
}
