import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UIDataItem, UIKeyValueItem, UIModalService, UIResponsiveService, UISessionService } from '@clickdealer/ngx-apps';

@Component({
    selector: 'gbl-dealer-contact-modal',
    templateUrl: './dealer-contact.component.html'
})

export class GBLDealerContactModalComponent implements OnDestroy {
    
    dealer: UIDataItem = this.uiSessionService.getItem('dealer');
    dealerContactItems: UIKeyValueItem[] = this.getDealerContactItems();
    destroyed$: Subject<boolean> = new Subject<boolean>();
    
    /**
     * Constructs class and injects dependencies.
     * Constructs modal config.
     * Subscribes to dealer object in session service.
     *
     * @param {UIModalService} uiModalService
     * @param {UISessionService} uiSessionService
     * @param {UIResponsiveService} uiResponsiveService
     */
    constructor(
        private uiModalService: UIModalService,
        private uiSessionService: UISessionService,
        private uiResponsiveService: UIResponsiveService
    ) {
        
        this.uiModalService.setConfig({
            title: 'Contact Us',
            position: this.uiResponsiveService.screenIsLTE('sm3') ? 'left' : 'right',
            width: 'sm2'
        });
        
        this.uiSessionService.getSubject('dealer').pipe(takeUntil(this.destroyed$)).subscribe((dealer: UIDataItem) => {
            this.dealer = dealer;
            this.dealerContactItems = this.getDealerContactItems();
        });
    }
    
    /**
     * Returns dealer contact methods in key value list
     *
     * @returns {UIKeyValueItem[]}
     */
    getDealerContactItems(): UIKeyValueItem[] {
        
        const contactItems: UIKeyValueItem[] = [];
        
        if (this.dealer.phone_number) {
            contactItems.push({
                key: 'Call us',
                icon: 'fas fa-phone',
                value: {
                    type: 'anchorLink',
                    text: this.dealer.phone_number,
                    href: 'tel:' + this.dealer.phone_number,
                    target: '_blank'
                }
            });
        }
        
        if (this.dealer.email) {
            contactItems.push({
                key: 'Email us',
                icon: 'fas fa-envelope',
                value: {
                    type: 'anchorLink',
                    text: this.dealer.email,
                    href: 'mailto:' + this.dealer.email,
                    target: '_blank'
                }
            });
        }
        
        if (this.dealer.address) {
            contactItems.push({
                key: 'Visit us',
                description: this.dealer.address,
                icon: 'fas fa-map-marker-alt',
                value: {
                    type: 'button',
                    text: 'Show on Map',
                    theme: 'primary',
                    click: () => {
                        this.uiModalService.show(['global/dealer-map']);
                    }
                }
            });
        }
        
        return contactItems;
        
    }
    
    /**
     * Destroys component and any active subscriptions.
     */
    ngOnDestroy(): void {
        this.destroyed$.next(true);
    }
}
