import * as i0 from "@angular/core";
var DataLayerService = /** @class */ (function () {
    /**
     * Constructs class and injects dependencies.
     */
    function DataLayerService() {
        this.dataLayer = window['dataLayer'];
        this.dataLayer = this.dataLayer || [];
    }
    /**
     * Pushes item to data layer.
     */
    DataLayerService.prototype.push = function (item) {
        this.dataLayer.push(item);
    };
    DataLayerService.ngInjectableDef = i0.defineInjectable({ factory: function DataLayerService_Factory() { return new DataLayerService(); }, token: DataLayerService, providedIn: "root" });
    return DataLayerService;
}());
export { DataLayerService };
