export class Dealer {
    
    id: number;
    name: string;
    sub_domain: string;
    email: string;
    phone_number: string;
    
    [propName: string]: any;
    
    /**
     * Constructs class and appends additional properties.
     *
     * @param {Dealer} dealer
     */
    constructor(dealer: Dealer) {
        Object.assign(this, dealer)
    }
    
}
