import { Component } from '@angular/core';

@Component({
    selector: 'cca-bootstrap',
    templateUrl: './bootstrap.component.html'
})

export class BootstrapComponent {

}
