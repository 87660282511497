/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dealer-map.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dealer-map.component";
import * as i3 from "@clickdealer/ngx-apps";
var styles_GBLDealerMapModalComponent = [i0.styles];
var RenderType_GBLDealerMapModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GBLDealerMapModalComponent, data: {} });
export { RenderType_GBLDealerMapModalComponent as RenderType_GBLDealerMapModalComponent };
export function View_GBLDealerMapModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["class", "google-map"]], [[1, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dealer.map_embed_link; _ck(_v, 1, 0, currVal_0); }); }
export function View_GBLDealerMapModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gbl-dealer-map-modal", [], null, null, null, View_GBLDealerMapModalComponent_0, RenderType_GBLDealerMapModalComponent)), i1.ɵdid(1, 180224, null, 0, i2.GBLDealerMapModalComponent, [i3.UIModalService, i3.UISessionService, i3.UIResponsiveService], null, null)], null, null); }
var GBLDealerMapModalComponentNgFactory = i1.ɵccf("gbl-dealer-map-modal", i2.GBLDealerMapModalComponent, View_GBLDealerMapModalComponent_Host_0, {}, {}, []);
export { GBLDealerMapModalComponentNgFactory as GBLDealerMapModalComponentNgFactory };
